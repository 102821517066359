/*
 * @Author: hwu
 * @Date: 2020-11-17 09:43:05
 * @Description: 购物车接口
 * @LastEditTime: 2024-04-11 17:49:52
 */

import { posHttp } from '@/utils/axios'

/**
 * 导航页进菜谱页生成购物车
 * @param gotoMenu
 * @returns {*}
 */
export const goToMenuApi = gotoMenu => posHttp.post(`/cart/gotoMenu`, gotoMenu)

/**
 * 获取门店菜谱
 * @param cartId
 * @param userId
 * @returns {*}
 */
export const getStoreMenuApi = cartId =>
  posHttp.get(`/cart/getStoreMenu?cartId=${cartId}`)

/**
 * 菜谱页/弹窗添加商品
 * @param item
 * @returns {*}
 */
export const addItemApi = item => posHttp.post(`/cart/addItem`, item)

/**
 * 菜谱页/弹窗删减商品
 * @param cartId
 * @param itemId
 * @param userId
 * @returns {*}
 */
export const minusItemApi = (cartId, itemId, userId) =>
  posHttp.post(
    `/cart/minusItem?cartId=${cartId}&itemId=${itemId}&userId=${userId}`
  )

/**
 * 获取单品详情信息接口
 * @param {String} cartId 购物车Id
 * @param {String} itemId 商品Id
 * @param {String} userId 用户Id
 * @returns {*}
 */
export const getItemDetailApi = (cartId, itemId, userId) =>
  posHttp.get('/cart/getItemDetail', { params: { cartId, itemId, userId } })

/**
 * 获取套餐详情信息
 * @param {String} cartId 购物车Id
 * @param {String} itemId 商品Id
 * @param {String} userId 用户Id
 * @returns {*}
 */
export const getUnitItemDetailApi = (cartId, itemId, userId) =>
  posHttp.get('/cart/getUnitItemDetail', { params: { cartId, itemId, userId } })

/**
 * 获取券商品详情（直接从商品库查的
 * @param {String} itemId  商品Id
 */
export const getCouponItemDetailApi = (
  cartId,
  userId,
  itemId,
  productionModelId
) =>
  posHttp.get('/cart/itemDetailFromItemStore', {
    params: { cartId, userId, itemId, productionModelId }
  })

/**
 * 获取购物车详情接口
 * @param cartId
 * @param userId
 * @returns {*}
 */
export const getCartInfoApi = (cartId, userId) =>
  posHttp.get(`/cart/getCartInfo?cartId=${cartId}&userId=${userId}`)

/**
 * 修改购物车渠道
 * @param {String} cartId 购物车Id
 * @param {String} userId 用户id
 * @param {Number} serviceType 订单类型
 */
export const changeCartServiceTypeApi = (cartId, userId, serviceType) =>
  posHttp.get('/cart/changeServiceType', {
    params: { cartId, userId, serviceType }
  })

/**
 * 清除购物车
 * @param cartId
 * @param userId
 * @returns {*}
 */
export const clearCartApi = (cartId, userId) =>
  posHttp.get(`/cart/clearCart?cartId=${cartId}&userId=${userId}`)

/**
 * 购物车弹出框删减商品
 * @param cartId 购物车id
 * @param uniKey 商品唯一key
 * @param userId 用户id
 * @returns {*}
 */
export const minusCartItemApi = (cartId, uniKey, userId) =>
  posHttp.get(
    `/cart/minusCartItem?cartId=${cartId}&uniKey=${uniKey}&userId=${userId}`
  )

/**
 * 购物车弹出框增加商品
 * @param cartId 购物车id
 * @param uniKey 商品唯一key
 * @param userId 用户id
 * @returns {*}
 */
export const addCartItemApi = (cartId, uniKey, userId) =>
  posHttp.get(
    `/cart/addCartItem?cartId=${cartId}&uniKey=${uniKey}&userId=${userId}`
  )

/**
 * 购物车点选好了，生成订单
 * @param cartId
 * @param userId
 * @returns {*}
 */
export const createOrderApi = (cartId, userId) =>
  posHttp.get(`/cart/gotoOrderDetail?cartId=${cartId}&userId=${userId}`)

/**
 * 获取购物车内商品的关联商品
 * @param {String} cartId 购物车Id
 */
export const getBaseFeeApi = cartId =>
  posHttp.get(`/cart/getBaseFee?cartId=${cartId}`)

/**
 * 获取基本收费项
 * @param {String} cartId 购物车Id
 * @param {String} userId 用户id
 */
export const getRelationItemApi = (cartId, userId) =>
  posHttp.get(`/cart/getItemRelationShip?cartId=${cartId}&userId=${userId}`)

/**
 * 保存赠品选择
 * @param {String} cartId 购物车商品
 * @param {String} userId 用户id
 * @param {Array} items 赠品数据
 */
export const setPromoFreeItemsApi = (cartId, userId, items) =>
  posHttp.post(`/cart/setPromoFreeItems`, items, { params: { cartId, userId } })

/**
 * 客扫支付超时
 * @param {String} cartId 购物车id
 */
export const customScanPayOutOfTimeApi = cartId =>
  posHttp.get('/cart/customScanPayOutOfTime', { params: { cartId } })

/**
 * 客扫支付。pos机修改订单
 * @param {String} cartId 购物车id
 */
export const posUpdateOrderApi = cartId =>
  posHttp.get('/cart/posUpdateOrder', { params: { cartId } })

/**
 * 客扫支付。pos机修改订单(后买单模式)
 * @param {String} cartId 购物车id
 */
export const posUpdateOrderForAfterPayApi = orderId =>
  posHttp.get('/cart/posUpdateOrder/payLater', { params: { orderId } })

/**
 * 根据商品条码获取商品信息
 * @param {String} cartId 购物车id
 * @param {String} userId 用户id
 * @param {String} barCode 商品条码
 * @returns
 */
export const getItemByBarCodeApi = (cartId, userId, barCode) =>
  posHttp.get('/cart/item/scan', { params: { cartId, userId, barCode } })
