/*
 * @Author: hwu
 * @Date: 2021-02-22 17:30:02
 * @Description: file content
 * @LastEditTime: 2024-05-27 10:42:12
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import orderRouters from './modules/order'
import errorRouters from './modules/error'
import transRouter from './modules/trans'
import tempRouters from './modules/temp'
import cardRouters from './modules/card'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: { name: 'ErrorGeneral' }
  },
  ...orderRouters,
  ...errorRouters,
  ...transRouter,
  ...tempRouters,
  ...cardRouters
]

const router = new VueRouter({
  routes
})

export default router
