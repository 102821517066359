/*
 * @Author: hwu
 * @Date: 2021-01-26 17:40:09
 * @Description: 全局注册函数
 * @LastEditTime: 2024-05-27 10:54:48
 */

import Vue from 'vue'
import router from './router'
import validate from '@/utils/validate'
import { checkAppApi } from '@/api/apk'
import { $bappSdk } from '@weihong/bapp-websdk'

/**
 * 跳往错误页面
 * @param {String} errMsg 错误提示
 */
const linkToError = (errMsg) => {
  router.push({
    name: 'ErrorGeneral',
    query: {
      msg: errMsg
    }
  })
}
Vue.prototype.$linkToError = linkToError

/**
 * 七牛云图片路径转换
 * @param {*} key 图片id
 * @param {*} width 压缩的图片宽度（宽度固定，高度自适应）
 * @param {*} compress 是否压缩
 * @param {*} radius 是否圆角 （'200'表示200像素， '!50p'表示50%）
 * @returns
 */
Vue.prototype.$transferQnyUrl = (key, width = 400, compress = true, radius = '') => {
  if (!key) {
    return ''
  }
  const domian = process.env.VUE_APP_QNY_LOAD_URL

  // 不需要压缩，就直接返回
  if (!compress) {
    if (radius) {
      return `${domian}${key}?roundPic/radius/${radius}`
    }
    return `${domian}${key}`
  }

  if (radius) {
    return `${domian}${key}?imageView2/0/w/${width}&roundPic/radius/${radius}`
  }

  return `${domian}${key}?imageView2/0/w/${width}`
}

/**
 * pos机点餐，点击表单需要关闭软键盘
 */
Vue.prototype.$closeKeyboard = () => {
  document.activeElement.blur()
}

/**
 * 关闭浏览器页面
 */
Vue.prototype.$closeWindow = () => {
  // 微信浏览器
  if (window.WeixinJSBridge) {
    window.WeixinJSBridge.call('closeWindow')
    return
  }
  // 支付宝浏览器
  if (window.AlipayJSBridge) {
    window.AlipayJSBridge.call('closeWebview')
    return
  }
  // 电脑端浏览器
  window.opener = null
  window.open('', '_self', '')
  window.close()
}

/**
 * entran
 */
Vue.prototype.$checkEntranceType = (entranceType) => {
  // 如果配置文件未打开验证开关，则不校验
  if (process.env.VUE_APP_NEED_CHECK_ENTRANCE !== '1') {
    return
  }
  // 如果是pos机点餐并且当前环境不是我们的apk，则跳往错误页面
  if (entranceType === 1 && checkAppApi() !== '1' && !$bappSdk.isBApp()) {
    linkToError('请用pos机扫码或悠订App扫码下单')
    return
  }

  // 如果是微信点餐并且当前环境不是微信浏览器，则跳往错误页面
  if (entranceType === 3 && !validate.weixin()) {
    linkToError('请用微信扫码下单')
    return
  }

  // 如果是支付宝点餐并且当前环境不是支付宝浏览器，则跳往错误页面
  if (entranceType === 4 && !validate.alipay()) {
    linkToError('请用支付宝扫码下单')
  }
}

// cdn基础路径，用于在页面中动态引入静态文件的前缀
Vue.prototype.$cdnBaseUrl = process.env['VUE_APP_CDN_BASE_URL']

/**
 * 十六进制颜色值（HEX）转换成 RGB格式的颜色值
 * @param {*} hex 十六进制颜色值
 */
Vue.prototype.$hexToRgb = (hex) => {
  return 'rgb(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ')'
}
/**
 * 十六进制颜色值（HEX）转换成 RGBA格式的颜色值
 * @param {*} hex 十六进制颜色值
 * @param {*} opacity 透明度
 */
Vue.prototype.$hexToRgba = (hex, opacity) => {
  return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' + parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')'
}
