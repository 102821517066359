const ORDER_KEY = 'pos_order'
const MEMBER_KEY = 'pos_member'

export default {
  order: {
    getStorageData () {
      return JSON.parse(sessionStorage.getItem(ORDER_KEY))
    },
    saveStorageData (data) {
      return sessionStorage.setItem(ORDER_KEY, JSON.stringify(data))
    },
    removeStorageData () {
      return sessionStorage.removeItem(ORDER_KEY)
    }
  },
  member: {
    getStorageData () {
      return JSON.parse(sessionStorage.getItem(MEMBER_KEY))
    },
    saveStorageData (data) {
      return sessionStorage.setItem(MEMBER_KEY, JSON.stringify(data))
    },
    removeStorageData () {
      return sessionStorage.removeItem(MEMBER_KEY)
    }
  }
}
