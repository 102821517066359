/*
 * @Author: hwu
 * @Date: 2021-02-22 17:30:02
 * @Description: file content
 * @LastEditTime: 2024-03-28 21:25:49
 */
import Vue from 'vue'
import Vuex from 'vuex'
import order from './modules/order'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    order
  }
})

export default store
