/*
 * @Author: hwu
 * @Date: 2020-11-17 10:46:43
 * @Description: 购物车的store内存
 * @LastEditTime: 2024-04-11 18:21:22
 */

import Storage from '@/utils/storage'
import { addItemApi, minusItemApi } from '@/api/cart'
import { Toast } from 'vant'
import { $bappSdk } from '@weihong/bapp-websdk'
export default {
  namespaced: true,
  state: {
    // 门店Id
    storeId: '',
    // 桌位Id
    tableId: '',
    // 用户Id
    userId: '',
    // 扫码点餐的环境, 1-pos机，3-微信，4-支付宝，6-pad
    entranceType: 1,
    // 是否是pad点餐
    isPad: !!$bappSdk.isBPadApp(),
    // 点餐模式：1-送餐到桌，2-自取
    kdsModel: '1',
    // 买单模式。1-先买单，2-后买单， 3-仅挂账
    payModel: 1,
    // 购物车Id
    cartId: 0,
    // 购物车商品总数量
    cartQty: 0,
    // 购物车总金额
    cartAmount: 0,
    // 购物车商品
    cartItems: [],
    // 菜谱页总商品
    menuItems: [],
    // 购物车赠品活动
    freePromos: [],
    // 是否存在称重商品
    existsWeighing: false,
    // 购物车动画小球(预存5个)
    balls: [
      { index: 0, show: false },
      { index: 1, show: false },
      { index: 2, show: false },
      { index: 3, show: false },
      { index: 4, show: false }
    ],
    dropBalls: [],
    // 后买单买单之后跳转的页面路径(用于今日订单和未付款订单的待办进入买单页)，没有就回到点餐选人数页面
    afterPayBackUrl: ''
  },
  mutations: {
    SET_BASE_INFO: (state, data) => {
      state.storeId = data.storeId
      state.tableId = data.tableId
      state.userId = data.userId
      state.entranceType = data.entranceType
      if (!state.isPad) {
        state.isPad = data.isPad || !!$bappSdk.isBPadApp()
      }
      state.kdsModel = data.kdsModel
      state.payModel = data.payModel
      state.cartId = data.cartId
      state.existsWeighing = data.existsWeighing
    },
    SET_IS_PAD: (state, data) => {
      state.isPad = data
    },
    SET_CART_ID: (state, data) => {
      state.cartId = data
    },
    SET_CART_QTY: (state, data) => {
      state.cartQty = data
    },
    SET_CART_AMOUNT: (state, data) => {
      state.cartAmount = data
    },
    SET_CART_ITEMS: (state, data) => {
      state.cartItems = data
    },
    SET_FREE_PROMOS: (state, data) => {
      state.freePromos = data
    },
    SET_MENU_ITEMS: (state, data) => {
      state.menuItems = data
    },
    SET_EXISTS_WEIGHING: (state, data) => {
      state.existsWeighing = data
    },
    SET_BALLS: (state, data) => {
      state.balls = data
    },
    SET_DROP_BALLS: (state, data) => {
      state.dropBalls = data
    },
    SET_AFTER_PAY_BACK_URL_UPDATE: (state, data) => {
      state.afterPayBackUrl = data
    }
  },
  actions: {
    /**
     * 由于vuex刷新页面会丢失，所以每次重载页面的时候，会从缓存里面同步数据
     */
    syncStateFromStorageVuex({ commit }) {
      const storageData = Storage.order.getStorageData()
      const baseInfo = {
        storeId: storageData.storeId || '',
        tableId: storageData.tableId || '',
        userId: storageData.userId || '',
        entranceType: storageData.entranceType || '',
        isPad: storageData.isPad || !!$bappSdk.isBPadApp(),
        kdsModel: storageData.kdsModel || '1',
        payModel: storageData.payModel || 1,
        cartId: storageData.cartId || '',
        existsWeighing: storageData.existsWeighing || false
      }
      commit('SET_BASE_INFO', baseInfo)
    },
    saveBaseInfoToStorageVue({ state }) {
      const baseInfo = {
        storeId: state.storeId,
        tableId: state.tableId,
        userId: state.userId,
        entranceType: state.entranceType,
        isPad: state.isPad,
        kdsModel: state.kdsModel,
        payModel: state.payModel,
        cartId: state.cartId,
        existsWeighing: state.existsWeighing
      }
      Storage.order.saveStorageData(baseInfo)
    },
    /**
     * 将扫码后获得的基本信息存入缓存
     * @param {Object} baseInfo 基础信息（storeId、tableId、userId、entranceType、kdsModel）
     */
    initBaseInfoVuex({ commit, dispatch }, baseInfo) {
      commit('SET_BASE_INFO', baseInfo)
      dispatch('saveBaseInfoToStorageVue')
    },
    /**
     * 手动更新isPad（为了调试）
     * @param {*} isPad  s
     */
    updateIsPadVuex({ commit, dispatch }, isPad) {
      commit('SET_IS_PAD', isPad)
      dispatch('saveBaseInfoToStorageVue')
    },
    /**
     * 更新缓存里面的cartId
     * @param {String} cartId 购物车Id
     */
    updateCartIdVuex({ commit, dispatch }, cartId) {
      commit('SET_CART_ID', cartId)
      dispatch('saveBaseInfoToStorageVue')
    },
    /**
     *  初始化菜谱商品列表数据（进入菜谱页面时，调用接口获取当前门店的商品列表，并将用户购买的商品同步到购物车数据）
     * @param {Array} itemList 菜谱商品列表
     */
    initMenuItemsVuex({ commit, state, dispatch }, itemList) {
      const cartItems = state.cartItems
      // 菜谱里面是否有称重商品
      let existsWeighing = false

      itemList.forEach((x) => {
        x.items.forEach((y) => {
          y.quantity = 0
          y.weight = 0
          // 处理已添加购物车商品数量
          cartItems.forEach((a) => {
            a.items.forEach((b) => {
              if (b.parentId === y.parentId && b.promoType !== 2) {
                y.quantity += b.quantity
                y.weight += b.weight || 0
              }
            })
          })
          // 判断菜谱是否有称重商品
          if (y.weighingStatus === 1) {
            existsWeighing = true
          }
        })
      })
      commit('SET_MENU_ITEMS', itemList)
      // 菜谱里面是否有称重商品
      commit('SET_EXISTS_WEIGHING', existsWeighing)
      dispatch('saveBaseInfoToStorageVue')
    },
    /**
     * 清空菜谱商品列表内所有商品的已选数量（清空购物车时调用）
     */
    clearMenuItemQtyVuex({ commit, state }) {
      const menuItems = state.menuItems
      menuItems.forEach((cate) => {
        cate.items.forEach((item) => {
          item.quantity = 0
          item.weight = 0
        })
      })
      commit('SET_MENU_ITEMS', menuItems)
    },
    /**
     * 更新菜谱商品列表缓存数据（单个商品添加或减少购物车时调用）
     * @param {String} parentId 商品父级Id（菜谱商品列表，是不拆规格的，多规格商品添加之后只根据parentId；来汇集已选数量的）
     * @param {*} itemQty 商品数量
     */
    updateMenuItemQtyVuex({ commit, state }) {
      const menuItems = state.menuItems
      const cartItems = state.cartItems
      menuItems.forEach((x) => {
        x.items.forEach((y) => {
          y.quantity = 0
          y.weight = 0
          cartItems.forEach((a) => {
            a.items.forEach((b) => {
              if (b.parentId === y.parentId && b.promoType !== 2) {
                y.quantity += b.quantity
                y.weight += b.weight
              }
            })
          })
        })
      })
      commit('SET_MENU_ITEMS', menuItems)
    },
    /**
     * 更新购物车缓存信息
     * @param {Object} cartInfo 添加、删除购物车商品之后，接口返回购物车数据
     */
    updateCartInfoVuex({ commit, dispatch }, cartInfo) {
      console.log(cartInfo)
      const cartQty = cartInfo.quantity || 0
      const cartAmount = cartInfo.amount || 0
      const cartItems = cartInfo.categoryList || []
      const freePromos = cartInfo.freePromos || []
      // 商品父级Id（菜谱商品列表，是不拆规格的，多规格商品添加之后只根据parentId；来汇集已选数量的）
      commit('SET_CART_QTY', cartQty)
      commit('SET_CART_AMOUNT', cartAmount)
      commit('SET_CART_ITEMS', cartItems)
      commit('SET_FREE_PROMOS', freePromos)
      if (cartItems.length === 0) {
        dispatch('clearMenuItemQtyVuex')
        return
      }
      dispatch('updateMenuItemQtyVuex')
      dispatch('saveBaseInfoToStorageVue')
    },
    /**
     * 减少购物车内商品数量
     * @param {String} itemId 商品Id
     */
    minusItemQtyVuex({ dispatch, state }, itemId) {
      return new Promise((resolve, reject) => {
        minusItemApi(state.cartId, itemId, state.userId)
          .then((res) => {
            const cartInfo = res.data
            cartInfo.parentId = itemId
            dispatch('updateCartInfoVuex', cartInfo)
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /**
     * 增加购物车内商品数量
     * @param {String} parentId 商品父Id
     * @param {Array} items 添加的商品列表
     */
    addItemQtyVuex({ dispatch, state }, { parentId, items }) {
      const data = {
        cartId: state.cartId,
        userId: state.userId,
        parentId: parentId,
        items: items
      }
      return new Promise((resolve, reject) => {
        addItemApi(data)
          .then((res) => {
            if (res.status !== 0) {
              Toast(res.msg)
              resolve()
              return false
            }
            const cartInfo = res.data
            cartInfo.parentId = parentId
            dispatch('updateCartInfoVuex', cartInfo)

            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    initBallsVuex({ state, commit }) {
      const balls = state.balls
      balls.forEach((b) => {
        b.show = false
      })
      commit('SET_BALLS', balls)
    },
    toggleShowBallVuex({ state, commit }, { index, isShow, el }) {
      const balls = state.balls
      balls[index].show = isShow
      balls[index].el = el
      commit('SET_BALLS', balls)
    },
    addDropBallVuex({ state, commit }, { ball }) {
      const dropBalls = state.dropBalls
      dropBalls.push(ball)
      commit('SET_DROP_BALLS', dropBalls)
    },
    shiftDropBallVuex({ state, commit }) {
      const dropBalls = state.dropBalls
      if (this.dropBalls.length <= 1) {
        return
      }
      const shiftBall = dropBalls.shift()
      commit('SET_DROP_BALLS', dropBalls)
      return shiftBall
    },
    updateAfterPayBackUrlVuex({ commit }, afterPayBackUrl) {
      commit('SET_AFTER_PAY_BACK_URL_UPDATE', afterPayBackUrl)
    }
  }
}
