/*
 * @Author: hwu
 * @Date: 2020-08-28 00:26:19
 * @Description: 扫码点餐页面路由
 * @LastEditTime: 2024-04-01 16:44:16
 */

export default [
  {
    path: '/order/pos/scan',
    name: 'OrderPosScan',
    meta: {
      title: '开始点餐'
    },
    component: () => import('@/views/order/PosScan.vue')
  },
  {
    path: '/order/guide',
    name: 'OrderGuide',
    meta: {
      title: '悠订'
    },
    component: () => import('@/views/order/Guide.vue')
  },
  {
    path: '/order/menu',
    name: 'OrderMenu',
    meta: {
      title: '菜谱'
    },
    component: () => import('@/views/order/Menu.vue')
  },
  {
    path: '/order/pad/menu',
    name: 'OrderPadMenu',
    meta: {
      title: '菜谱'
    },
    component: () => import('@/views/order/pad/Menu.vue')
  },
  {
    path: '/order/payment',
    name: 'OrderPayment',
    meta: {
      title: '订单详情'
    },
    component: () => import('@/views/order/Payment.vue')
  },
  {
    path: '/order/paymentAfterPay',
    name: 'OrderPaymentAfterPay',
    meta: {
      title: '订单详情'
    },
    component: () => import('@/views/order/PaymentAfterPay.vue')
  }
]
