/*
 * @Author: hwu
 * @Date: 2021-01-20 22:56:08
 * @Description: file content
 * @LastEditTime: 2024-05-27 10:10:37
 */
export default [
  {
    path: '/card/buy',
    name: 'StaffCardBuy',
    meta: {
      title: '会员卡'
    },
    component: () => import('@/views/card/StaffCardBuy.vue')
  }
]
