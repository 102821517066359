export default [
  {
    path: '/wechat/auth',
    name: 'WechatAuth',
    meta: {
      title: '授权公众号'
    },
    component: () => import('@/views/temp/WechatAuth.vue')
  },
  {
    path: '/wechat/auth/success',
    name: 'WechatAuthSuccess',
    meta: {
      title: '授权成功'
    },
    component: () => import('@/views/temp/WechatAuthSuccess.vue')
  }
]
