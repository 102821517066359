/*
 * @Author: hwu
 * @Date: 2020-11-17 17:05:54
 * @Description: 页面加载前的一些数据验证和同步
 * @LastEditTime: 2021-01-06 21:19:28
 */

import router from './router'
import store from './store'
import Storage from '@/utils/storage'

router.afterEach((to, from) => {
  const orderStorageData = Storage.order.getStorageData()
  if (orderStorageData && !store.state.order.storeId) {
    store.dispatch('order/syncStateFromStorageVuex')
  }
})
