/*
 * @Author: hwu
 * @Date: 2021-01-20 22:56:08
 * @Description: file content
 * @LastEditTime: 2021-01-20 23:03:07
 */
export default [
  {
    path: '/error/general',
    name: 'ErrorGeneral',
    meta: {
      title: '温馨提示'
    },
    component: () => import('@/views/error/General.vue')
  }
]
