import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Toast } from 'vant'
import appSdk from '@weihong/bapp-websdk'
// w-ui
import '@/lib/wui'
import '@/permission'
import '@/global'

import './assets/css/theme.less'
import './styles/index.scss'
import './assets/iconfont/iconfont.css'

require('./assets/iconfont/iconfont.js')

Vue.use(require('vue-wechat-title'))
Vue.use(Toast)
Vue.use(appSdk, router)

Vue.config.productionTip = false

Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
