/*
 * @Author: hwu
 * @Date: 2022-06-09 14:47:40
 * @Description: file content
 * @LastEditTime: 2022-06-09 14:49:07
 */
export default [
  {
    path: '/trans/wx/program',
    name: 'TransWxProgram',
    meta: {
      title: '打开小程序'
    },
    component: () => import('@/views/trans/TransWxProgram.vue')
  }
]
